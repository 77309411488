import React from "react";
import * as styles from "./sectionIndicator.module.scss";
import Link from "gatsby-link";
import classNames from "classnames";

const SectionIndicator = ({activeSectionID}) => {
  return (
    <div className={styles.container}>
      <Link to={"/#home"} className={classNames({
        [styles.lineWrapper]: true,
        [styles.active]: activeSectionID === 0}
      )}>
        <div className={styles.line} />
      </Link>
      <Link to={"/#work"} className={classNames({
        [styles.lineWrapper]: true,
        [styles.active]: activeSectionID === 1}
      )}>
        <div className={styles.line} />
      </Link>
        <Link to={"/#celbrate"} className={classNames({
            [styles.lineWrapper]: true,
            [styles.active]: activeSectionID === 2}
        )}>
            <div className={styles.line} />
        </Link>
      <Link to={"/#about"} className={classNames({
        [styles.lineWrapper]: true,
        [styles.active]: activeSectionID === 3}
      )}>
        <div className={styles.line} />
      </Link>
      <Link to={"/#contact"} className={classNames({
        [styles.lineWrapper]: true,
        [styles.active]: activeSectionID === 4}
      )}>
        <div className={styles.line} />
      </Link>
    </div>
  );
}

export default SectionIndicator;
