import React from "react";
import * as styles from "./tinyTitle.module.scss";
import {Link} from "gatsby";
import classNames from "classnames";

const TinyTitle = ({visible}) => {
  return (
    <Link to={"/#home"} className={classNames({
      [styles.link]: true,
      [styles.visible]: visible
    })}>
      <h4>Martin Lédl</h4>
    </Link>
  );
}

export default TinyTitle;
