import * as React from "react";
import {useLayoutEffect, useRef} from "react";
import {gsap} from "gsap";

import * as styles from "./animatedSection.module.scss";

const AnimatedSection = ({children, onSectionEnter, onSectionLeave, onSectionLeaveBack, navigationID}) => {
  const sectionRef = useRef();

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "-50% top",
        end: "80% bottom",
        onEnter: onSectionEnter,
        onLeave: onSectionLeave,
        onLeaveBack: onSectionLeaveBack,
        markers: false,
        scrub: 0.3
      },
    })

    tl.fromTo(sectionRef.current, {
      opacity: 0,
      x: -100,
    }, {
      opacity: 1,
      x: 0
    });

    return () => {
      tl?.kill();
    };
  }, []);

  return (
    <section ref={sectionRef} className={styles.section} data-navigation-id={navigationID}>
      {children}
    </section>
  );
}

AnimatedSection.defaultProps = {
  showMarkers: false
}

export default AnimatedSection;
