import * as React from "react";
import * as styles from "../styles/index.module.scss";
import Seo from "../components/seo/seo";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import Nav from "../components/nav/nav";
import AnimatedSection from "../components/animatedSection/animatedSection";
import {useEffect, useState} from "react";
import {graphql, Link} from "gatsby";
import classNames from "classnames";
import SectionIndicator from "../components/sectionIndicator/sectionIndicator";
import TinyTitle from "../components/tinyTitle/tinyTitle";
import Tilt from "react-parallax-tilt";

const PROJECTS = [
  {
    name: "rezidencesobin.cz",
    imageName: "rezidencesobin",
    image: null,
    url: "https://rezidencesobin.cz"
  },
  {
    name: "atelierviscum.cz",
    imageName: "atelierviscum",
    image: null,
    url: "https://atelierviscum.cz"
  },
  {
    name: "ladema.cz",
    imageName: "ladema",
    image: null,
    url: "https://ladema.cz"
  },
  {
    name: "vracimoperak.cz",
    imageName: "vracimoperak",
    image: null,
    url: "https://vracimoperak.cz"
  }
];

const HomePage = ({data}) => {
  const projects = PROJECTS;
  projects.map(
    project => project.image = getImage(data.allFile.edges.find(
      edge => edge.node.name === project.imageName
    ).node)
  );
  const [projectIndex, setProjectIndex] = useState(0);
  const [autoActive, setAutoActive] = useState(true);
  const [activeSectionID, setActiveSectionID] = useState(0);

  useEffect(() => {
    if (autoActive) {
      const timer = setTimeout(
        () => setNextProject(),
        10000
      )

      return () => clearTimeout(timer)
    }
  }, [projectIndex, autoActive]);

  async function setNextProject() {
    setProjectIndex(current => (current + 1) % PROJECTS.length);
  }

  async function setPreviousProject() {
    setProjectIndex(current => (current + 1) % PROJECTS.length);
  }

  function nextProject() {
    setAutoActive(false);
    setNextProject();
  }

  function previousProject() {
    setAutoActive(false);
    setPreviousProject();
  }

  return (
    <>
      <Seo />
      <Nav />
      <TinyTitle visible={activeSectionID > 0} />
      <SectionIndicator activeSectionID={activeSectionID} />
      <main className={styles.main}>

        <AnimatedSection
          navigationID="home"
          onSectionEnter={() => setActiveSectionID(0)}
        >
          <div className={styles.text}>
            <h2>Martin Lédl</h2>
            <h3>web developer</h3>
            <h3>graphic designer</h3>
          </div>
        </AnimatedSection>


        <AnimatedSection
          navigationID="work"
          onSectionEnter={() => setActiveSectionID(1)}
          onSectionLeaveBack={() => setActiveSectionID(0)}
        >
          <div className={styles.text}>
            <h2>Web Projects</h2>
            <h3>
              <a href={projects[projectIndex].url}
                 target="_blank"
                 rel="noopener noreferrer"
              >
                {projects[projectIndex].name}
              </a>
            </h3>
            <div className={styles.controlButtons}>
              <p className={styles.controlButton}
                 onClick={previousProject}
              ><i className="far fa-long-arrow-left"/>
              </p>
              <p className={styles.controlButton}
                 onClick={nextProject}
              ><i className="far fa-long-arrow-right"/>
              </p>
            </div>
          </div>
          <a href={projects[projectIndex].url}
             className={styles.imageWrapper}
             target="_blank"
             rel="noopener noreferrer"
          >
            {projects.map((project, index) => (
              <div className={classNames({
                [styles.webPreviewWrapper]: true,
                [styles.visible]: projectIndex === index
              })} key={project.name}
              >
                <GatsbyImage
                  image={project.image}
                  alt="website preview on mobile"
                  className={styles.webPreview}
                  objectFit={"contain"}
                  placeholder={"none"}
                  backgroundColor={"transparent"}
                />
              </div>
            ))}
          </a>
        </AnimatedSection>

        <AnimatedSection
            navigationID="celbrate"
            onSectionEnter={() => setActiveSectionID(2)}
            onSectionLeaveBack={() => setActiveSectionID(1)}
        >
          <div className={styles.text}>
            <h2>Celbrate App</h2>
            <h3>Track birthdays and name days of friends and relatives with ease and style</h3>
            <p className={styles.controlButton}>
              {/*Available for iOS on <a href="" target="_blank" rel="noopener noreferrer">App Store</a>*/}
              development in progress
            </p>
            <Link to={"/celbrate"} className={styles.controlButton}>Read more <i className="far fa-long-arrow-right"/></Link>
          </div>
          <a href="/celbrate"
             className={styles.imageWrapper}
             target="_blank"
             rel="noopener noreferrer"
          >
            <StaticImage
                src="../images/celbrate-preview.png"
                alt="celbrate app preview on iphones"
                className={styles.webPreview}
                objectFit={"contain"}
            />
          </a>
        </AnimatedSection>

        <AnimatedSection
          navigationID="about"
          onSectionEnter={() => setActiveSectionID(3)}
          onSectionLeaveBack={() => setActiveSectionID(2)}
        >
          <div className={styles.text}>
            <h2>About me</h2>
            <h3>I love creating digital products and helping people bring their visions to life.</h3>
            <Link to={"/about"} className={styles.controlButton}>Read more <i className="far fa-long-arrow-right"/></Link>
          </div>
          <div className={styles.imageWrapper}>
            <Tilt glareEnable={true} tiltReverse={true} glareReverse={true}>
              <StaticImage
                  src="../images/dreamworks.png"
                  alt="a piece of digital art I created"
                  className={styles.webPreview}
                  objectFit={"contain"}
              />
            </Tilt>
          </div>
        </AnimatedSection>


        <AnimatedSection
          navigationID="contact"
          onSectionEnter={() => setActiveSectionID(4)}
          onSectionLeaveBack={() => setActiveSectionID(3)}
        >
          <div className={styles.text}>
            <h2>Let me help you</h2>
            <h3><a href="mailto:martinledl.tech@gmail.com">martinledl.tech@gmail.com</a></h3>
            <h3><a href="tel:+420 777 803 934">+420 777 803 934</a></h3>
            <div className={styles.icons}>
              <a href="https://github.com/martinledl" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github" aria-label="Go to my github" />
              </a>
              <a href="https://www.facebook.com/martin.ledl.315" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f" aria-label="Go to my facebook" />
              </a>
              <a href="https://www.instagram.com/martas_ledl/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram" aria-label="Go to my instagram" />
              </a>
              <a href="https://medium.com/@martinledl" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-medium" aria-label="Go to my blog on Medium" />
              </a>
            </div>
          </div>
        </AnimatedSection>
      </main>
    </>
  )
}

export const pageQuery = graphql`
    query {
        allFile(filter: {relativePath: {glob: "previews/*"}}) {
            edges {
                node {
                    name
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default HomePage;
